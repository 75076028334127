
import BarcodeLabelList from '@/components/BarcodeLabelList.vue'
import BarcodeSymbologiesSelection from '@/components/BarcodeSymbologiesSelection.vue'
import MultipleBarcodeScanMixin from '@/components/mixins/MultipleBarcodeScanMixin.vue'
import ScanArea from '@/components/svg/ScanArea.vue'
import { BE_LINKED, FETCH_BARCODE, LINK_TO, SAVE_SCANNED_BARCODES } from '@/store/actions'
import { getEstimateBarcodeLinked, linkingBarcodes } from '@/utils/api'
import { ELinkType } from '@/utils/enums'
import { IBarcode, IBarcodeDefinitionType } from 'smartbarcode-web-core/src/utils/types/index'
import { mixins, Options } from 'vue-class-component'
import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'

type TLinkType = {
  type: ELinkType
  label: string
  operation: typeof LINK_TO | typeof BE_LINKED
}

@Options({
  components: {
    ScanArea,
    BarcodeLabelList,
    BarcodeSymbologiesSelection,
  },
  name: 'BarcodeLinkScan',
})
export default class BarcodeLinkScan extends mixins(MultipleBarcodeScanMixin) {
  loading = false
  dialogVisible = false
  checkScannedBarcode = false
  children = [] as IBarcode[]
  estimateLinkedCount = 0
  isReadyToScan = true

  async created() {
    this.loading = true
    await this.getBarcodeInfo()
    const { count } = await getEstimateBarcodeLinked(this.$route.params.barcodeId)
    this.estimateLinkedCount = count
    this.loading = false
  }

  async beforeRouteLeave(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
    await this.onLeaveGuard(to, from, next)
  }

  async getBarcodeInfo() {
    if (this.$route.params?.barcodeId && this.$route.params?.project) {
      await this.$store.dispatch(FETCH_BARCODE, { id: this.$route.params?.barcodeId })
    }
  }

  get barcode() {
    return this.$store.state.barcode.barcode
  }

  onCheckScannedBarcode() {
    this.checkScannedBarcode = true
    this.dialogVisible = true
  }

  onCloseDialog() {
    this.dialogVisible = false
    this.checkScannedBarcode = false
  }

  barcodeTypeInfo(barcodeType: string): Record<string, IBarcodeDefinitionType> {
    return this.$store.state.project?.details?.barcodeTypes[barcodeType]
  }

  async validateBarcode(resultScanned: IBarcode) {
    if (this.barcode.id === resultScanned.id) throw this.$t('barcode_linking_by_itself_error')

    const currentProjectId = this.$store.state.project.details.id
    const scannedBCProjectId = resultScanned.projectId
    if (currentProjectId !== scannedBCProjectId) throw this.$t('scanned_barcode_not_same_project')

    if (!resultScanned.isActivated) throw this.$t('barcode not activated')

    const bcTypeInfo = this.barcodeTypeInfo(resultScanned.barcodeType)
    if (!bcTypeInfo.allowToBeLinked) throw this.$t('barcode_linking_not_allow')

    const data = {
      barcodeId: this.barcode?.id,
      targetBarcodeIds: [resultScanned?.id],
      isDryRun: true,
    }

    await linkingBarcodes(this.linkType.type, data)
  }

  get linkType(): TLinkType {
    return this.$route.name === `${ELinkType.LINK_TO}-scan`
      ? { type: ELinkType.LINK_TO, label: this.$t('linking.link_to_read_result'), operation: LINK_TO }
      : { type: ELinkType.BE_LINKED, label: this.$t('linking.be_linked_read_result'), operation: BE_LINKED }
  }

  forward() {
    this.$store.dispatch(SAVE_SCANNED_BARCODES, this.barcodes)
    this.$router.push({
      name: 'scanned-barcode-list',
      params: {
        title: this.linkType.label,
        operation: this.linkType.operation,
      },
    })
  }
}
