<template>
  <div class="edit-view display-in-center view" v-loading.fullscreen="loading || !isReadyToScan">
    <div class="step-label">
      {{ $t('pair') }}
    </div>
    <div class="barcode-scanner">
      <div class="scan-area" v-if="isScanditReaderProject" id="scan-area"></div>
      <div id="scan-area" class="scan-area" v-else>
        <video id="video"></video>
      </div>
      <BarcodeSymbologiesSelection class="absolute bottom-2 z-50 left-2 flex w-52"
        @update:onSymbologiesChange="setBarcodeSymbologies" v-model:symbologies="symbologies" />
      <ScanArea />
    </div>
    <div class="barcode-number-block">
      <span>
        {{ $t('Number of Scanned Barcodes') }}
      </span>
      <span class="label-box" @click="onCheckScannedBarcode">
        {{ scannedBarcodeList.length }}
      </span>
    </div>
    <div v-if="estimateChildCount > 0" class="barcode-number-block">
      <span>
        {{ $t('Number of reserved children barcode') }}
      </span>
      <span class="label-box">
        {{ estimateChildCount }}
      </span>
    </div>
    <el-button circle :disabled="scannedBarcodeList.length === 0" type="primary" class="submit-button"
      @click="submit()">
      {{ $t('OK') }}
    </el-button>
  </div>
  <el-dialog :lock-scroll="true" center v-model="dialogVisible" width="90%" top="0" :show-close="false"
    :destroy-on-close="false" :title="checkScannedBarcode ? $t('Scanned Barcodes') : ''">
    <template v-if="checkScannedBarcode">
      <p v-if="scannedBarcodeList.length === 0">
        {{ $t('no barcodes') }}
      </p>
      <BarcodeLabelList v-else :barcodes="scannedBarcodeList || []" />
    </template>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="onCloseDialog">
          {{ $t('OK') }}
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import ScanArea from '@/components/svg/ScanArea.vue'
import BarcodeLabelList from '@/components/BarcodeLabelList.vue'
import { SAVE_SCANNED_BARCODES, PAIRING, CLEAR_ERROR } from '@/store/actions'
import MultipleBarcodeScanMixin from '@/components/mixins/MultipleBarcodeScanMixin.vue'
import { IBarcode, IBarcodeDefinitionType, TError } from 'smartbarcode-web-core/src/utils/types/index'
import { getChildren, getEstimateBarcodeChildren, pairBarcode } from '@/utils/api'
import errorHandler from '@/utils/errorHandler'
import BarcodeSymbologiesSelection from '@/components/BarcodeSymbologiesSelection.vue'
import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'
@Options({
  components: {
    ScanArea,
    BarcodeLabelList,
    BarcodeSymbologiesSelection,
  },
  name: 'Pairscan',
})
export default class PairScan extends mixins(MultipleBarcodeScanMixin) {
  loading = false
  dialogVisible = false
  checkScannedBarcode = false
  children = [] as IBarcode[]
  estimateChildCount = 0
  extParams = {} as Record<string, unknown>
  isReadyToScan = true

  async created() {
    try {
      this.loading = true
      this.children = await getChildren(this.$route.params.barcodeId)
      const { count } = await getEstimateBarcodeChildren(this.$route.params.barcodeId)
      this.estimateChildCount = count
    } catch (error) {
      errorHandler(error as TError)
    } finally {
      this.loading = false
    }
  }

  async beforeRouteLeave(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
    await this.onLeaveGuard(to, from, next)
  }

  onCheckScannedBarcode() {
    this.checkScannedBarcode = true
    this.dialogVisible = true
  }

  onCloseDialog() {
    this.dialogVisible = false
    this.checkScannedBarcode = false
  }

  barcodeTypeInfo(barcodeType: string): Record<string, IBarcodeDefinitionType> {
    return this.$store.state.project?.details?.barcodeTypes[barcodeType]
  }

  get isAllowForcePairing() {
    return this.$store.state.project?.details?.allowForcePairing || false
  }

  isBarcodeInChildrenList(barcode: IBarcode): boolean {
    return !!this.children.find((bc) => bc.id === barcode.id)
  }

  async validateBarcode(resultScanned: IBarcode) {
    const currentProjectId = this.$store.state.project.details.id
    const scannedBCProjectId = resultScanned.projectId
    if (currentProjectId !== scannedBCProjectId) {
      throw this.$t('scanned_barcode_not_same_project')
    }

    const { barcodeType, hasParent } = resultScanned

    if (this.parentBarcodeId === resultScanned?.id) throw this.$t('barcode_pairing_by_itself_error')

    if (!barcodeType) {
      throw this.$t('barcode not activated')
    }

    if (this.isBarcodeInChildrenList(resultScanned)) {
      throw this.$t('scanned_barcode_in_children_list')
    }

    if (hasParent && !this.isAllowForcePairing) {
      throw this.$t('barcode_already_paired')
    }

    const barcodeTypeItem = this.barcodeTypeInfo(barcodeType)
    if (!barcodeTypeItem?.allowToBePaired || resultScanned.isDeactivated || !resultScanned.isReadyToAddTrackData) {
      throw this.$t('barcode_can_not_be_paired')
    }

    if (
      resultScanned?.reservedParentIds?.length > 0 &&
      !resultScanned?.reservedParentIds?.includes(this.parentBarcodeId)
    ) {
      throw this.$t('errors.3046')
    }

    const data = {
      parentBarcodeId: this.parentBarcodeId,
      childrenBarcodeIds: [resultScanned?.id],
      isDryRun: true,
    }
    try {
      await pairBarcode(data)
    } catch (error) {
      if (error === '3044' && this.isAllowForcePairing) {
        await this.$confirm('', this.$t('confirm_overwrite_parent'), {
          confirmButtonText: this.$t('ok'),
          confirmButtonClass: 'danger',
          cancelButtonText: this.$t('cancel'),
        })
          .then(async () => {
            const payload = {
              ...data,
              forcePairing: true,
            }
            await pairBarcode(payload)
            this.extParams = {
              ...this.extParams,
              forcePairing: true,
            }
          })
          .catch((err: unknown) => {
            throw err
          })
          .finally(() => this.$store.commit(CLEAR_ERROR))
        return
      }
      throw error
    }
  }

  get parentBarcodeId() {
    return this.$store.state.barcode?.barcode?.id || ''
  }

  forward() {
    this.$store.dispatch(SAVE_SCANNED_BARCODES, this.barcodes)
    this.$router.push({
      name: 'scanned-barcode-list',
      params: {
        title: this.$t('pairing_read_result'),
        operation: PAIRING,
        extParams: JSON.stringify(this.extParams),
      },
    })
  }
}
</script>

<style lang="scss" scoped>
@import './src/assets/css/mixins.scss';

.scan-area {
  height: 100%;
}

.edit-view.view {
  padding-left: 0;
  padding-right: 0;
}

.step-label {
  margin-bottom: 25px;
}

.barcode-scanner {
  height: 390px;
  background: rgb(32, 32, 32);
  position: relative;
}

.barcode-scanner video {
  width: 100%;
  height: 100%;
}
</style>
